<template>
  <div class="sub-header-thin-div">
    <v-card :class="`common-content-div ${isMobile ? '' : 'w-75 mx-auto my-25'}`">
      <v-card-text class="center">
        <p :class="`font-bold color-white ${isMobile ? 'font-30 mt-10' : 'font-40 mt-20'}`">{{ $t('eventbox_cookie_policy') }}</p>
      </v-card-text>
      <v-card-text class="center">
        <p :class="`color-white ${isMobile ? 'my-10 font-18' : 'my-30 font-30'}`">{{ $t('cookie_description')}}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>